export const attendData = [
  {
    user_number: '20206104',
    name: '김나영_20206104',
    panopto_attendances: [
      {
        item_number: 6,
        attendance_state: 'ABSENCE',
      },
      {
        item_number: 1,
        attendance_state: 'ABSENCE',
      },
      {
        item_number: 2,
        attendance_state: 'ABSENCE',
      },
      {
        item_number: 3,
        attendance_state: 'ABSENCE',
      },
      {
        item_number: 4,
        attendance_state: 'ABSENCE',
      },
      {
        item_number: 5,
        attendance_state: 'ABSENCE',
      },
    ],
    zoom_attendances: [
      {
        item_number: 2,
        attendance_state: 'LATE',
      },
      {
        item_number: 3,
        attendance_state: 'ABSENCE',
      },
    ],
  },
  {
    user_number: '20146119',
    name: '김병준_20146119',
    panopto_attendances: [
      {
        item_number: 6,
        attendance_state: 'ABSENCE',
      },
      {
        item_number: 1,
        attendance_state: 'ABSENCE',
      },
      {
        item_number: 2,
        attendance_state: 'ABSENCE',
      },
      {
        item_number: 3,
        attendance_state: 'ABSENCE',
      },
      {
        item_number: 4,
        attendance_state: 'LATE',
      },
      {
        item_number: 5,
        attendance_state: 'ABSENCE',
      },
    ],
    zoom_attendances: [
      {
        item_number: 2,
        attendance_state: 'LATE',
      },
      {
        item_number: 3,
        attendance_state: 'ABSENCE',
      },
    ],
  },
];

const MAX_STUDY_PARKING = 16;

/**
 * 배열을 정렬하고, 결석 상태를 포함한 배열을 반환합니다.
 *
 * @param {Array} array - 정렬할 배열
 * @returns {Array} 정렬된 배열과 결석 상태를 포함한 배열
 * 
 *  ATTENDANCE 출석
 *  LATE 지각
 *  ABSENCE 결석

 *  NO_PANOPTO_DATA 파놉토 출결정보 없음
 *  NO_ZOOM_DATA 줌 출결정보 없음

 *  EXCUSED_ABSENCE 공결
 *  NOT_DATA 값 없음
 */
export const stretchVideoArr = (array) => {
  const sortedArr = array.toSorted((a, b) => a.item_number - b.item_number);
  const result = Array.from({ length: MAX_STUDY_PARKING }, (_, index) => {
    const conditinalArr = sortedArr.find((item) => item.item_number === index + 1);
    return { item_number: index + 1, attendance_state: conditinalArr ? conditinalArr.attendance_state : 'NOT_DATA' };
  });
  return result;
};

export const mixVideoArr = (arr1, arr2) => {
  const newArray = [];

  const newArray1 = stretchVideoArr(arr1);
  const newArray2 = stretchVideoArr(arr2);

  for (let i = 0; i < MAX_STUDY_PARKING; i++) {
    newArray.push(newArray1[i]);
    newArray.push(newArray2[i]);
  }

  return newArray;
};

export const videoStateSwitch = (item) => {
  let state = '';
  switch (item) {
    case 'ATTENDANCE':
      state = '출석';
      break;
    case 'ABSENCE':
      state = '결석';
      break;
    case 'LATE':
      state = '지각';
      break;

    default:
      state = '기타';
      break;
  }
  return state;
};
//   const maxArrLength = Math.max(sortArr1.length, sortArr2.length);

//   for (let i = 0; i < maxArrLength; i++) {
//     result.push(sortArr1[i]);
//     result.push(sortArr2[i]);
//   }
//   return result;
