import { DashboardStream, SubjectNotice } from 'components/common/dashboard';
import BreadCrumb from 'components/shared/BreadCrumb';
import { DashBoardNews } from 'components/shared/dashboard/DashBoardNews';
import { useGetCommonApi, useGetQuery, useGetTeacherQuery } from 'hooks';
import { StudentProgress } from 'pages/teacher/dashboard';
import DashboardTopCards from './DashboardTopCards';

export function AdminDashBoard() {
  const {
    // 학기정보
    semesterData,
    semesterIsLoading,
    semesterIsError,
  } = useGetCommonApi();
  const {
    // 활동 스트림
    activitySteamData,
    activitySteamIsLoading,
    activitySteamIsError,
  } = useGetQuery();
  const { teacherStudentProgressData } = useGetTeacherQuery();

  return (
    <>
      <main id='main' className='main'>
        <BreadCrumb home={'/'} sublink={'/'} title={'Home'} subTitle={'홈'} />
        <section className='section dashboard'>
          <div className='row'>
            <div className='col-lg-8'>
              {/* 상단 카드 */}
              <DashboardTopCards />

              {/* 학습과목 */}
              <StudentProgress cardTitle={semesterData?.data?.name} />

              {/* 과목 공지사항 */}
              <SubjectNotice data={teacherStudentProgressData} cardTitle={semesterData?.data?.name} />
            </div>
            <div className='col-lg-4'>
              {/* 활동 스트림 */}
              <DashboardStream data={activitySteamData?.today_activity_list} isLoading={activitySteamIsLoading} />

              {/* 공지사항 & 뉴스 */}
              <DashBoardNews />
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
