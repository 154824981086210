import { useGetCommonApi } from 'hooks';
import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useRecoilState } from 'recoil';
import { zoomStudentState } from 'recoil/zoom/zoomStudentState';

export const ZoomHeader = ({ data, isLoading }) => {
  const [zoomState, setZoomState] = useRecoilState(zoomStudentState);
  const { semesterData, semesterIsLoading, semesterIsError } = useGetCommonApi();
  const [optionData, setOptionData] = useState([]);

  const handleSelectBox = (e) => {
    const findSubject = data?.find((el) => +el.course_id === +e.value);
    setZoomState({
      ...zoomState,
      subjectId: e.value,
      subjectInformation: findSubject,
    });
  };

  useEffect(() => {
    return setOptionData(
      data?.map((item) => ({
        value: item.course_id,
        label: item.course_name,
      }))
    );
  }, [data]);

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(
        data?.map((item) => ({
          value: item.course_id,
          label: item.course_name,
        }))
      );
    });

  return (
    <div className='card p-20 px-4'>
      {/* 헤더 */}
      <div className='d-flex justify-content-between align-items-center'>
        <h3 className='d-flex align-items-center white-nowrap fw-bold'>
          <i className='bi bi-bank2 me-2 color-primary'></i>
          {/* 학기 정보 */}
          {semesterData ? semesterData?.data?.name : '기본학기'}
        </h3>

        <div className='d-flex align-items-center w-100 ps-5'>
          <label htmlFor='subjectSelectTitle' className='white-nowrap me-3'>
            과목
          </label>
          <AsyncSelect
            className='w-100'
            defaultInputValue={zoomState.subjectInformation?.course_name || ''}
            placeholder='과목 선택'
            isLoading={isLoading}
            cacheOptions
            defaultOptions={optionData}
            onChange={handleSelectBox}
            loadOptions={promiseOptions}
          />
        </div>
      </div>
    </div>
  );
};
