import { setSessionStorage } from 'assets/constant';
import EmptyComponent from 'components/common/EmptyComponent';
import { ZoomIsSuccess } from 'components/common/zoom/ZoomIsSuccess';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { zoomStudentState } from 'recoil/zoom/zoomStudentState';
import { zoomTypeSwitch } from 'util/zoom/zoomUtil';

export const ZoomWeekItem = ({ item, index }) => {
  const user = useRecoilValue(loginUser);
  const zoomState = useRecoilValue(zoomStudentState);
  const navigate = useNavigate();
  const handleGoingDetail = (meetingId) => {
    // 세션 저장
    // zoomDetail
    setSessionStorage(zoomState?.subjectInformation?.courseName, 'getDetailData');
    setSessionStorage(user?.name, 'zoomDetailStudent');
    navigate(`/zoom/detail/${meetingId}`);
  };
  return (
    <div key={item.moduleId} className='rounded-2 overflow-hidden bg-white mb-5'>
      <div className='p-20 Blue_primary fw-bold text-white'>
        <span className='me-1'>{index + 1}</span>주차
      </div>
      <div className=''>
        {item.zoomMeetingList?.length === 0 ? (
          <div className='p-30 pb-2 fz-14'>
            <EmptyComponent transform={true} text='등록된 강의가 없습니다.' />
          </div>
        ) : (
          <div className='p-30 pb-2 fz-14'>
            <Table className=''>
              <thead className='fw-bold --Palette-DarkGray-80'>
                <tr className='border-bottom'>
                  <th className='text-start p-1 py-3' style={{ width: '39.29%' }}>
                    제목
                  </th>
                  <th className='text-start p-1 py-3' style={{ width: '18.57%' }}>
                    참여시간
                  </th>
                  <th className='p-1 py-3 text-start' style={{ width: '18.57%' }}>
                    출석 참여도 (%)
                  </th>
                  <th className='p-1 py-3' style={{ width: '11.79%' }}>
                    상태
                  </th>
                  <th className='p-1 py-3' style={{ width: '11.79%' }}>
                    참여현황
                  </th>
                </tr>
              </thead>
              <tbody className='--Palette-DarkGray-80'>
                {item.zoomMeetingList?.map((el) => {
                  const zoomType = zoomTypeSwitch(el.found, el.isAttendance);
                  return (
                    <tr key={el.meetingId} className='border-bottom'>
                      <td className='text-start p-1 py-3'>{el.title}</td>
                      <td className='text-start p-1 py-3'>{el.totalTime ? el.totalTime : '-'}</td>
                      <td className='text-start p-1 py-3'>
                        <div>{el.percentage ? `${el.percentage}%` : '-'}</div>
                      </td>
                      <td className='text-start p-1 py-3'>
                        <div className='w-100 h-100 d-flex justify-content-center'>
                          <ZoomIsSuccess type={zoomType} />
                        </div>
                      </td>
                      <td className='text-start p-1 py-3'>
                        <div className='d-flex justify-content-center align-items-center'>
                          <button
                            onClick={() => handleGoingDetail(el.meetingId)}
                            // to={`/zoom/detail/${el.meetingId}`}
                            className={`border-none py-2 px-3 rounded-2 text-white ${zoomType === 'red' || zoomType === 'gray' ? 'bg-gray' : 'bg-second'}`}
                            disabled={zoomType === 'red' || zoomType === 'gray'}
                          >
                            자세히보기
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};
