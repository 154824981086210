/**
 * ZoomIsSuccess 컴포넌트는 Zoom 출석 상태를 나타내는 컴포넌트입니다.
 *
 * @component
 * @param {Object} props - ZoomIsSuccess 컴포넌트의 속성
 * @param {string} props.type - 출석 상태를 나타내는 문자열 ('red', 'orange', 'green', 'gray')
 * @returns {JSX.Element} Zoom 출석 상태를 나타내는 p 요소
 */
export const ZoomIsSuccess = ({ type }) => {
  return (
    <p
      className={`fw-bold noto-sans fz-12 py-1 px-3 rounded-5 text-nowrap lh-22
      ${type === 'red' && 'isSuccess-red'} 
      ${type === 'orange' && 'isSuccess-orange'} 
      ${type === 'green' && 'isSuccess-green'} 
      ${type === 'gray' && 'isSuccess-gray'} 
      `}
    >
      {type === 'red' && '미참여'}
      {type === 'orange' && '늦은 참여'}
      {type === 'gray' && '연동중'}
      {type === 'green' && '참여'}
    </p>
  );
};
