import JoditEditor from 'jodit-react';
import { useRef } from 'react';

const BoardJodit = ({ value, onChange }) => {
  const editor = useRef(null);
  return (
    <div className='d-flex py-2'>
      <label htmlFor=''>내용</label>
      <div className='bg-red w-100'>
        <JoditEditor
          className='editor_custom--style w-100'
          ref={editor}
          value={value}
          tabIndex={1} // tabIndex of textarea
          onChange={(newContent) => onChange(newContent)}
        />
      </div>
      {/* <div className='notReset w-100 editor_custom--style'>{editor}</div> */}
    </div>
  );
};

export default BoardJodit;
