import { useQuery } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { SpinnerLoading } from 'components/common';
import { useGetRequiredApi } from 'hooks/useGetRequiredApi';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { zoomStudentState } from 'recoil/zoom/zoomStudentState';
import { ZoomCardTitle } from './ZoomCardTitle';
import { ZoomHeader } from './ZoomHeader';
import { ZoomWeekItem } from './ZoomWeekItem';

export const ZoomStudent = () => {
  const navigate = useNavigate();
  const user = useRecoilValue(loginUser);
  const [zoomState, setZoomState] = useRecoilState(zoomStudentState);

  const [filterData, setFilterData] = useState([]);

  const {
    //   온라인 학습현황 과목선택 셀렉트박스 쿼리
    studentSelectBoxQueryData,
    studentSelectBoxQueryIsLoading,
    studentSelectBoxQueryIsError,
  } = useGetRequiredApi();

  const {
    data: zoomWeekData,
    isLoading: zoomWeekDataIsLoading,
    isError: zoomWeekDataIsError,
  } = useQuery({
    queryKey: ['zoomWeekData', zoomState.subjectId, user],
    queryFn: () => getDataQuery(`/zoom/attendance/me?courseId=${zoomState.subjectId}`),
    enabled: !!zoomState.subjectId,
  });

  useEffect(() => {
    if (!zoomState.subjectId) return setFilterData([]);
    setFilterData(zoomWeekData?.data);
  }, [zoomWeekData, zoomState]);

  return (
    <div className=''>
      {/* 위쪽 */}
      <ZoomHeader data={studentSelectBoxQueryData} isLoading={studentSelectBoxQueryIsLoading} />

      {zoomWeekDataIsLoading && <SpinnerLoading />}
      {filterData?.length > 0 && (
        <>
          {/* 셀렉트 information */}
          <div className=''>
            <ZoomCardTitle />

            {/* 아래 카드 */}
            <div>
              <div className='mb-3'>
                {/* <SubjectBtnList
                            activeIndex={filterIndex}
                            setFilterIndex={setFilterIndex}
                            setFilterData={setFilterData}
                            subjectMainData={zoomWeekData?.data}
                          /> */}
              </div>

              {/* 주차 아이템 */}

              {filterData?.map((item, index) => (
                <ZoomWeekItem key={item.moduleId} item={item} index={index} />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
