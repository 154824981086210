import { AssignmentQuiz, DashboardStream, SubjectNotice } from 'components/common/dashboard';
import { SubjectNoticePopup } from 'components/popup';
import BreadCrumb from 'components/shared/BreadCrumb';
import { DashBoardNews } from 'components/shared/dashboard/DashBoardNews';
import { useGetCommonApi, useGetQuery } from 'hooks';
import { useGetRequiredApi } from 'hooks/useGetRequiredApi';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { subjectNoticePopupState } from 'recoil/dashboard/subjectNoticePopupState';
import { LeadingSubject } from './studysubject';

export function Main() {
  const navigate = useNavigate();
  const [subjectPopupState, setSubjectPopupState] = useRecoilState(subjectNoticePopupState);
  const {
    // 학기정보
    semesterData,
    semesterIsLoading,
    semesterIsError,
  } = useGetCommonApi();

  const {
    assignmentData,
    assignmentIsLoading,
    assignmentIsError,

    subjectsStatesData,
    subjectsStatesIsLoading,
    subjectsStatesIsError,

    homeSubjectNoticeData,
    homeSubjectNoticeIsLoading,
    homeSubjectNoticeIsError,

    dashboradLectureNoticeData,
    dashboradLectureNoticeIsLoading,
    dashboradLectureNoticeIsError,

    // 활동 스트림
    activitySteamData,
    activitySteamIsLoading,
    activitySteamIsError,
  } = useGetQuery();

  const {
    //   온라인 학습현황 과목선택 셀렉트박스 쿼리
    studentSelectBoxQueryData,
    studentSelectBoxQueryIsLoading,
    studentSelectBoxQueryIsError,
  } = useGetRequiredApi();

  useEffect(() => {
    return () => {
      setSubjectPopupState({ visible: false, data: null });
    };
  }, []);

  return (
    <>
      <main id='main' className='main'>
        <BreadCrumb home={'/'} sublink={'/'} title={'Home'} subTitle={'홈'} />
        <section className='section dashboard'>
          <div className='row'>
            <div className='col-lg-8'>
              <div className='row'>
                {/* 과제 */}
                <AssignmentQuiz
                  assignment={assignmentData?.assignment_not_submitted}
                  quiz={assignmentData?.quiz_not_submitted}
                  isLoading={assignmentIsLoading}
                />

                {/* 학습과목 */}
                <LeadingSubject
                  data={subjectsStatesData}
                  isLoading={subjectsStatesIsLoading}
                  cardTitle={semesterData?.data.name}
                />

                {/* 과목 공지사항 */}
                <SubjectNotice
                  data={studentSelectBoxQueryData}
                  cardTitle={semesterData?.data.name}
                  isLoading={studentSelectBoxQueryIsLoading}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              {/* 활동 스트림 */}
              <DashboardStream data={activitySteamData?.today_activity_list} isLoading={activitySteamIsLoading} />

              {/* 공지사항 & 뉴스 */}
              <DashBoardNews />
            </div>
          </div>
        </section>
      </main>
      {subjectPopupState.visible && <SubjectNoticePopup />}
    </>
  );
}
