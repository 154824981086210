function Footer() {
  return (
    <div className='text-center'>
      <p className='font-black font-center mb-4'>
        © copyright <span className='fw-bold'>Cha University.</span> All Rights Reserved
      </p>
    </div>
  );
}
export default Footer;
