import { useGetCommonApi } from 'hooks';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CloseButton } from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css';
import { Link } from 'react-router-dom';

export default function NoticePopup() {
  const [noticeIndex, setNoticeIndex] = useState(0);
  const [checkOneDayPopup, setCheckOneDayPopup] = useState(false);
  const [visible, setVisible] = useState(false);
  const {
    // 로그인시 팝업보여주는 api
    noticePopupData,
    noticePopupIsLoading,
    noticePopupIsError,
  } = useGetCommonApi();

  const handleClickNoticeIndex = (index) => {
    setNoticeIndex(index);
  };

  const handleCheckboxChange = (e) => {
    setCheckOneDayPopup(e.target.checked);
  };

  const handleClosePopup = () => {
    setVisible(false);
    const option = {
      maxAge: 24 * 60 * 60,
    };
    if (checkOneDayPopup) return localStorage.setItem('cha_notice_popup', moment().format('YYYY-MM-DD'), option);
  };

  useEffect(() => {
    const popupCookie = localStorage.getItem('cha_notice_popup');
    if (popupCookie === moment().format('YYYY-MM-DD')) return setVisible(false);

    localStorage.removeItem('cha_notice_popup');
    const checkPopupNoneLength = noticePopupData?.data?.length > 0;
    if (checkPopupNoneLength) return setVisible(true);
    return setVisible(false);
  }, [noticePopupData]);

  const handleKeyEvent = (e) => {
    if (e.keyCode === 27) {
      handleClosePopup();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyEvent);

    return () => {
      document.removeEventListener('keydown', handleKeyEvent);
    };
  }, []);

  return (
    <>
      {visible && (
        <>
          <div className='backdrop w-100 h-100 position-fixed top-0 start-0 z-5000'></div>
          <section
            className='position-fixed top-50-left-50 transform-center z-5000 card wh-80p p-4'
            //
          >
            <div className='d-flex justify-content-between border-bottom py-3'>
              <h3 className='d-flex align-items-center fw-bold fz-22'>{noticePopupData?.data[noticeIndex]?.title}</h3>
              <CloseButton tabIndex={1} onClick={handleClosePopup} style={{ fontSize: '3rem' }} />
            </div>
            {
              <div className='overflow-auto h-100'>
                <div
                  className='dashboardPopupNotice ql-editor'
                  dangerouslySetInnerHTML={{
                    __html: noticePopupData?.data[noticeIndex]?.content,
                  }}
                ></div>
              </div>
            }

            <footer className='d-flex justify-content-between'>
              {/* 체크박스 */}
              <div className='d-flex align-items-center cursor-pointer p-1'>
                <input
                  type='checkbox'
                  onChange={handleCheckboxChange}
                  checked={checkOneDayPopup}
                  className='me-2 cursor-pointer'
                  name='noticePopupCheckbox'
                  id='noticePopupCheckbox'
                  tabIndex={2}
                />
                <label className='cursor-pointer' htmlFor='noticePopupCheckbox'>
                  하루 동안 열지 않기
                </label>
              </div>

              {/* 아이템 인덱스 */}
              <ul className='d-flex'>
                {noticePopupData &&
                  noticePopupData?.data.map((el, index) => (
                    <li key={el.id}>
                      <Link
                        className={`p-2 rounded me-2 color-primary ${noticeIndex === index && 'color-second'} `}
                        onClick={() => handleClickNoticeIndex(index)}
                        to='#'
                      >
                        {index + 1}
                      </Link>
                    </li>
                  ))}
              </ul>
            </footer>
          </section>
        </>
      )}
    </>
  );
}
