import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { adminQueryKeyState } from 'recoil/adminpopup/adminQueryKeyState';
import { useUserState } from 'recoil/useUserState';
import { DropMenu, DropMenuItem } from '.';
import { DropMenuSpread } from './DropMenuSpread';
import { MenuItem } from './MenuItem';

export function Sidebar(props) {
  const [queryKeyState, setQueryKeyState] = useRecoilState(adminQueryKeyState);

  const loginUser = useRecoilValue(useUserState);

  const [accountIndex, setAccountIndex] = useState();
  const [supportingIndex, setSupportingIndex] = useState();
  const [tapIndex, setTapIndex] = useState(0);

  const navigate = useNavigate();

  // 새로고침시 사이드바 액티브 상태 수정
  const pathname = window.location.pathname;
  useLayoutEffect(() => {
    if (pathname.includes('/activity')) return setTapIndex(1);
    if (pathname.includes('/subject')) return setTapIndex(2);
    if (pathname.includes('/zoom')) return setTapIndex(3);
    if (pathname.includes('/attendance')) return setTapIndex(10);
    if (pathname.includes('/useraccounts')) {
      setTapIndex(4);
      setAccountIndex(0);
      return;
    }
    if (pathname.includes('/managementboard')) {
      setTapIndex(4);
      setAccountIndex(1);
      return;
    }

    if (pathname.includes('/notice')) {
      setTapIndex(5);
      setSupportingIndex(0);
      return;
    }
    if (pathname.includes('/faq')) {
      setTapIndex(5);
      setSupportingIndex(1);
      return;
    }
    if (pathname.includes('/reference')) {
      setTapIndex(5);
      setSupportingIndex(2);
      return;
    }
    if (pathname.includes('/manual')) {
      setTapIndex(5);
      setSupportingIndex(3);
      return;
    }

    if (pathname.includes('/setting')) return setTapIndex(6);
    return setTapIndex(0);
  }, [navigate, pathname]);

  const handlePageNumberReset = () => {
    setQueryKeyState({ ...queryKeyState, currentPage: 1 });
  };
  return (
    <>
      <aside id='sidebar' className='sidebar'>
        <div className=''>
          {/* 홈 */}
          <div className='mt-2'>
            <ul>
              {/* 홈 */}
              <MenuItem
                link={'/'}
                icon={'bi-grid-fill'}
                title={'홈'}
                setTapIndex={setTapIndex}
                tapIndex={0}
                active={tapIndex === 0}
              />
              {/* 활동 */}
              <MenuItem
                link={'/activity'}
                icon={'bi-collection-fill'}
                title={'활동'}
                setTapIndex={setTapIndex}
                tapIndex={1}
                active={tapIndex === 1}
              />
            </ul>

            {/* 학습현황 */}
            <div>
              <div className='mt-3 fw-bold fz-12'>
                <h3 className='px-3 py-2'>학습현황</h3>
              </div>

              <ul>
                <MenuItem
                  link={'/subject'}
                  icon={'bi-easel3-fill'}
                  title={'동영상 학습현황'}
                  setTapIndex={setTapIndex}
                  tapIndex={2}
                  active={tapIndex === 2}
                />
                <MenuItem
                  link={'/zoom'}
                  icon={'bi-pip-fill'}
                  title={'ZOOM 학습현황'}
                  setTapIndex={setTapIndex}
                  tapIndex={3}
                  active={tapIndex === 3}
                />
                {loginUser?.role === 'TEACHER' && (
                  <MenuItem
                    link={'/attendance'}
                    icon={'bi-calendar-check-fill'}
                    title={'온라인 주차현황'}
                    setTapIndex={setTapIndex}
                    tapIndex={10}
                    active={tapIndex === 10}
                  />
                )}
              </ul>
            </div>

            {/* 관리현황 */}
            {loginUser?.role === 'ADMIN' && (
              <div>
                <div className='mt-3 fw-bold fz-12'>
                  <h3 className='px-3 py-2'>관리현황</h3>
                </div>
                <DropMenu
                  icon={'bi-question-circle-fill'}
                  title={'계정관리'}
                  setTapIndex={setTapIndex}
                  tapIndex={4}
                  active={tapIndex === 4}
                >
                  <DropMenuItem
                    link={'/useraccounts'}
                    title={'사용자 계정관리'}
                    tapIndex={0}
                    active={accountIndex === 0}
                  />
                  <DropMenuItem
                    link={'/managementboard'}
                    title={'학습보드 권한관리'}
                    tapIndex={1}
                    active={accountIndex === 1}
                  />
                </DropMenu>
                {/*  */}
              </div>
            )}
          </div>

          {/* 지원안내 */}
          <div className='mt-5 pt-5'>
            <div className='mt-3 fw-bold fz-12'>
              <h3 className='px-3 py-2 --cha_yellow'>지원안내</h3>
            </div>
            <ul>
              <DropMenuSpread
                icon={'bi-question-circle-fill'}
                title={'지원센터'}
                setTapIndex={setTapIndex}
                tapIndex={5}
                active={tapIndex === 5}
              >
                <DropMenuItem link={'/notice'} title={'공지사항'} tapIndex={0} active={supportingIndex === 0} />
                <DropMenuItem link={'/faq'} title={'FAQ'} tapIndex={1} active={supportingIndex === 1} />
                <DropMenuItem link={'/reference'} title={'자료실'} tapIndex={2} active={supportingIndex === 2} />
                <DropMenuItem link={'/manual'} title={'매뉴얼'} tapIndex={3} active={supportingIndex === 3} />
              </DropMenuSpread>

              <MenuItem
                link={'/setting'}
                icon={'bi-gear-fill'}
                title={'계정 설정'}
                setTapIndex={setTapIndex}
                tapIndex={6}
                active={tapIndex === 6}
              />
            </ul>
          </div>
        </div>
      </aside>
    </>
  );
}
