import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { TOOLTIPMESSAGE } from 'assets/constant';
import { SpinnerLoading } from 'components/common';
import { IsVideoSuccess } from 'components/common/IsVideoSuccess';
import ResponsiveToolTip from 'components/common/tooltip/ResponsiveToolTip';
import { SubjectTeacherPopup } from 'components/popup';
import BreadCrumb from 'components/shared/BreadCrumb';
import { useGetRequiredApi } from 'hooks/useGetRequiredApi';
import moment from 'moment';
import { Table } from 'react-bootstrap';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { subjectValueState } from 'recoil/subject/subjectValueState';
import { categoryType, checkStatusValue, viewMoreTypeControl, viewTypeControl } from 'util/subject/subjectUtil';
import { SubjectBtnList } from '.';
import SubjectHeader from './SubjectHeader';
import SubjectItemCard from './SubjectItemCard';
import SubjectTitleCard from './SubjectTitleCard';

export function Subject() {
  const user = useRecoilValue(loginUser);
  const [subjectState, setSubjectState] = useRecoilState(subjectValueState);
  const resetSubjectState = useResetRecoilState(subjectValueState);

  // const [subjectId, setSubjectId] = useState(null);
  // const [courseId, setCourseId] = useState(null);
  const [visiblePopup, setVisiblePopup] = useState(false);
  const [filterIndex, setFilterIndex] = useState(0);
  const [filterData, setFilterData] = useState([]);

  const {
    //   온라인 학습현황 과목선택 셀렉트박스 쿼리
    studentSelectBoxQueryData,
    studentSelectBoxQueryIsLoading,
    studentSelectBoxQueryIsError,
  } = useGetRequiredApi();

  const {
    data: subjectMainData,
    isLoading: subjectMainIsLoading,
    isError: subjectMainIsError,
  } = useQuery({
    queryKey: ['subjectMainItem', subjectState.course_id, user],
    queryFn: () =>
      getDataQuery(
        `/subject/users/me/module_info/all?course_id=${subjectState.course_id}&course_name=${subjectState.course_name}`
      ),
    enabled: !!subjectState.course_id && !!subjectState.course_name,
  });

  useEffect(() => {
    const subjectName = studentSelectBoxQueryData?.find((el) => +el.id === +subjectState.subjectId);
    setSubjectState({ ...subjectState, subjectName: subjectName?.course_name });
  }, [subjectState.subjectId]);

  useEffect(() => {
    setFilterData(subjectMainData?.data);
  }, [subjectMainData]);

  useEffect(() => {
    resetSubjectState();
    return () => {
      resetSubjectState();
    };
  }, []);
  return (
    <>
      <main id='main' className={`main ${!subjectState.course_id && 'subject-icon-backdrop'}`}>
        <BreadCrumb
          home={'/subject'}
          sublink={'/subject'}
          title={'Online Learning Status'}
          subTitle={'동영상 학습현황'}
        />
        <section>
          <div className='row'>
            <div className='col'>
              <div className=''>
                {/* 셀렉트 박스 */}
                <SubjectHeader data={studentSelectBoxQueryData} isLoading={studentSelectBoxQueryIsLoading} />

                {subjectMainIsLoading && <SpinnerLoading />}
                {subjectMainData && (
                  <>
                    {/* 셀렉트 information */}
                    <div className=''>
                      {subjectMainData && <SubjectTitleCard />}

                      {/* 아래 카드 */}
                      <div>
                        <div className='mb-3 d-flex justify-content-between'>
                          <SubjectBtnList
                            activeIndex={filterIndex}
                            setFilterIndex={setFilterIndex}
                            setFilterData={setFilterData}
                            subjectMainData={subjectMainData?.data}
                          />

                          <ResponsiveToolTip>{TOOLTIPMESSAGE}</ResponsiveToolTip>
                        </div>

                        {/* 주차 아이템 */}
                        {filterData?.map((item, index) => {
                          if (item.items.length === 0)
                            return (
                              <div key={item.moduleId} className='rounded-2 overflow-hidden bg-white mb-5'>
                                {/* header */}
                                <div className='p-20 Blue_primary fw-bold text-white'>
                                  <span className='me-1'>{index + 1}</span>주차
                                </div>
                                {/* body */}
                                <div className='p-30 pb-2 fz-14'>
                                  <Table className=''>
                                    <thead className='fw-bold --Palette-DarkGray-80'>
                                      <tr className='border-bottom'>
                                        <th className='text-start p-1 py-3' style={{ width: '17.78%' }}>
                                          카테고리
                                        </th>
                                        <th className='text-start p-1 py-3' style={{ width: '38.51%' }}>
                                          제목
                                        </th>
                                        <th className='p-1 py-3' style={{ width: '11%' }}>
                                          상태
                                        </th>
                                        <th className='text-start p-1 py-3 text-center' style={{ width: '17.78%' }}>
                                          제출 완료 일자
                                        </th>
                                        <th className='text-start p-1 py-3 text-center' style={{ width: '11.00%' }}>
                                          점수
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className='--Palette-DarkGray-80'>
                                      <tr>
                                        <td className='text-center fz-16 fw-bold' colSpan={5}>
                                          <div className='w-100 h-100 py-3'>
                                            <img
                                              className='mb-3'
                                              width={80}
                                              height={80}
                                              src='/assets/img/subject--background1.png'
                                              alt='이미지'
                                            />
                                            <p>등록된 콘텐츠 없습니다.</p>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            );
                          return (
                            <SubjectItemCard item={item} index={index}>
                              <Table className=''>
                                <thead className='fw-bold --Palette-DarkGray-80'>
                                  <tr className='border-bottom'>
                                    <th className='text-start p-1 py-3' style={{ width: '17.78%' }}>
                                      카테고리
                                    </th>
                                    <th className='text-start p-1 py-3' style={{ width: '38.51%' }}>
                                      제목
                                    </th>
                                    <th className='p-1 py-3' style={{ width: '11%' }}>
                                      상태
                                    </th>
                                    <th className='text-start p-1 py-3 text-center' style={{ width: '17.78%' }}>
                                      제출 완료 일자
                                    </th>
                                    <th className='text-start p-1 py-3 text-center' style={{ width: '11.00%' }}>
                                      현황
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className='--Palette-DarkGray-80'>
                                  {item.items
                                    ?.filter((el) => el.type !== 'File')
                                    ?.map((el) => (
                                      <tr key={el.itemId} className='border-bottom'>
                                        <td className='text-start p-1 py-3' style={{ width: '17.78%' }}>
                                          {categoryType(el.type)}
                                        </td>
                                        <td className='text-start p-1 py-3' style={{ width: '38.51%' }}>
                                          {el.title}
                                        </td>
                                        <td className='text-start p-1 py-3' style={{ width: '11%' }}>
                                          <div className='w-100 h-100 d-flex justify-content-center'>
                                            {/* 타입이 zoom or File 이면 트레그먼트 반환 */}
                                            {viewTypeControl(el.type) ? <></> : <IsVideoSuccess state={el.status} />}
                                          </div>
                                        </td>
                                        <td className='text-start p-1 py-3 text-center' style={{ width: '17.78%' }}>
                                          {/* 타입이 zoom or File of 동영상 이라면 프레그먼트 반환 */}
                                          {viewMoreTypeControl(el.type) ? (
                                            <></>
                                          ) : el.completedAt ? (
                                            moment(el.completedAt).format('YYYY-MM-DD HH:mm:ss')
                                          ) : (
                                            '-'
                                          )}
                                        </td>
                                        <td className='text-start p-1 py-3 text-center' style={{ width: '11.00%' }}>
                                          {checkStatusValue(el.type, el)}
                                          {/* {viewMoreTypeControl(el.type) ? (
                                          '-'
                                        ) : (
                                          <>
                                            {isNaN(+el.score) ? '-' : el.score} /{' '}
                                            {isNaN(+el.possiblePoint) ? '-' : el.possiblePoint}
                                          </>
                                        )} */}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </SubjectItemCard>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
        {visiblePopup && <SubjectTeacherPopup setVisiblePopup={setVisiblePopup} />}
      </main>
    </>
  );
}
