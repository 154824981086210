import { calendarColor } from 'assets/constant';
import { CardHeader } from 'components/common';
import { NewsEmpty } from 'components/common/dashboard';
import { useGetCommonApi } from 'hooks';
import { useNavigate } from 'react-router';
import { removeTag } from 'util/common/dashboard';

export function DashBoardNews() {
  const navigate = useNavigate();
  const {
    // // 대시보드 뉴스&공지사항
    dashboardNoticeAndNews,
    dashboardNoticeAndNewsIsLoading,
    dashboardNoticeAndNewsIsError,
  } = useGetCommonApi();

  const handleGoingNotice = () => {
    navigate('/notice');
  };

  const handleGoingNoticeDetail = (id) => {
    navigate(`/notice/detail/${id}`);
  };

  return (
    <div className='card'>
      <div className='p-20'>
        <CardHeader title='뉴스 & 공지'>
          <div className='d-flex justify-content-between align-items-center w-100'>
            <p>교수학습지원센터</p>
            <button onClick={handleGoingNotice} className='btn btn-style-none'>
              <i className='bi bi-arrow-right-circle-fill color-DarkGray-20 fz-26'></i>
            </button>
          </div>
        </CardHeader>

        <div className='news'>
          {dashboardNoticeAndNews && dashboardNoticeAndNews?.data.length > 0 ? (
            <ul className='overflow-hidden cursor-pointer'>
              {dashboardNoticeAndNews?.data?.map((item, index) => (
                <li onClick={() => handleGoingNoticeDetail(item.id)} key={item.id} className='mb-3'>
                  <div className='d-flex justify-content-between h-80'>
                    {/* 이미지 */}
                    <div
                      className='me-4 dashboard-noticeAndNewsCard-imgbox rounded d-flex justify-content-center align-items-center wh-80'
                      style={{ background: calendarColor[index] }}
                    >
                      {/* <img className='h-100' src={'/assets/img/logo.svg'} alt='미리보기 이미지' /> */}
                    </div>
                    {/* 덱스트 */}
                    <div className='d-flex flex-column w-100'>
                      <h3 className='cardNewTitleOneLine fz-18 fw-bold --Palette-DarkGray-80'>{item.title}</h3>
                      <div className='dashboard-notice__text-overflow --Palette-DarkGray-50 mt-2'>
                        {removeTag(item?.content)}
                      </div>
                      {/* <div dangerouslySetInnerHTML={{ __html: item?.content }} /> */}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <NewsEmpty />
          )}
        </div>
      </div>
    </div>
  );
}
