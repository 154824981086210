import { useQueries } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { useRecoilValue } from 'recoil';
import { useUserState } from 'recoil/useUserState';

export const useStudentZoomQuery = () => {
  const user = useRecoilValue(useUserState);

  const zoomStudentApis = useQueries({
    queries: [
      // 학생 - [zoom 출석현황] 과목 드롭다운 API
      {
        queryKey: ['StudentActivityStream', user],
        queryFn: () => getDataQuery('/zoom/attendance/enrollment/me'),
      },
    ],
  });

  const [studentZoomSelectList] = zoomStudentApis.map((query) => query);
  const [studentZoomSelectListData] = zoomStudentApis.map((query) => query?.data?.data);

  const { isLoading: studentZoomSelectListIsLoading, isError: studentZoomSelectListIsError } = studentZoomSelectList;
  return {
    // 학생 - [zoom 출석현황] 과목 드롭다운 API
    studentZoomSelectListData,
    studentZoomSelectListIsLoading,
    studentZoomSelectListIsError,
  };
};
