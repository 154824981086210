/**
 * 학생의 강의 학습 여부를 나타내는 컴포넌트입니다.
 * 
 
 * @param {string} state - 비디오 상태를 나타내는 문자열입니다.
 * 
 * @returns {JSX.Element} 비디오 학습 상태를 나타내는 컴포넌트를 반환합니다.
 */
export function IsZoomSuccess({ state }) {
  const typeClasses = {
    출석: {
      type: 'isSuccess-green',
      text: '참여',
    },
    결석: {
      type: 'isSuccess-red',
      text: '결석',
    },
    지각: {
      type: 'isSuccess-orange',
      text: '지각',
    },
    기타: {
      type: '',
      text: '-',
    },
  };

  const typeClass = typeClasses[state].type || '';
  const typeText = typeClasses[state].text || '';

  return (
    <p
      className={`fw-bold noto-sans fz-12 py-1 px-3 rounded-5 text-nowrap lh-22
      ${typeClass}
        `}
    >
      {typeText}
    </p>
  );
}
