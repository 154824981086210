import BreadCrumb from 'components/shared/BreadCrumb';
import { useEffect } from 'react';
import { Outlet } from 'react-router';
import { useResetRecoilState } from 'recoil';
import { zoomStudentState } from 'recoil/zoom/zoomStudentState';

export function ZoomComponent() {
  const resetZoomState = useResetRecoilState(zoomStudentState);

  useEffect(() => {
    resetZoomState;
    return () => {
      resetZoomState();
    };
  }, []);
  return (
    <>
      {/* <Outlet /> */}
      <main id='main' className='main'>
        <BreadCrumb home={'/zoom'} sublink={'/zoom'} title={'ZOOM Attendance'} subTitle={'ZOOM'} />
        <section>
          <div className='row'>
            <div className='col'>
              <Outlet />
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
