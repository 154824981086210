import { useQuery } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { getSessionStorage } from 'assets/constant';
import { PrimaryButton } from 'components/common/button/PrimaryButton';
import BreadCrumb from 'components/shared/BreadCrumb';
import moment from 'moment';
import { Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom/dist';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';

export function ZoomItemDetailStudent() {
  const user = useRecoilValue(loginUser);
  const { meetingId } = useParams();
  const userName = getSessionStorage('zoomDetailStudent');
  const navigate = useNavigate();
  const getDetailData = getSessionStorage('zoomDetail');

  const { data: zoomDetailQueryData } = useQuery({
    queryKey: ['zoomStudentQuery', getDetailData],
    queryFn: () => getDataQuery(`/zoom/participants/detail?meetingId=${meetingId}&userName=${userName}`),
    enabled: !!meetingId && !!userName,
  });

  return (
    <>
      <BreadCrumb home={'/zoom'} sublink={'/zoom'} title={'ZOOM Attendance'} subTitle={'Zoom 출석현황'}>
        <Link className='ms-1 --Palette-DarkGray-60 '>
          {/* &nbsp;/<span> {getDetailData?.subjectTitle}</span>&nbsp;/<span> {zoomDetailData?.data?.topic}</span> */}
        </Link>
      </BreadCrumb>
      <div className=''>
        {/* 위쪽 */}
        <div className='card p-20 px-4'>
          {/* 헤더 */}
          <div className='d-flex justify-content-between align-items-center'>
            <h3 className='d-flex align-items-center white-nowrap fw-bold fz-24'>
              <span className='fw-normal'>{userName?.split('_')[1]}</span>_{userName?.split('_')[0]}
            </h3>

            <PrimaryButton onClick={() => navigate('../')} variant='primary'>
              목록
            </PrimaryButton>
          </div>
        </div>

        {
          // subjectMainData
          <>
            {/* 셀렉트 information */}

            {/* 아래 카드 */}
            <div className='card p-20'>
              <h3 className='fz-22 fw-bold mb-4'>{zoomDetailQueryData?.data.topic}</h3>
              <Table>
                <thead className='fw-bold border-bottom'>
                  <tr>
                    <th className='py-3' style={{ width: '7.07%' }}>
                      번호
                    </th>
                    <th className='py-3 text-start' style={{ width: '42.79%' }}>
                      참여 시작 시간
                    </th>
                    <th className='py-3 text-start' style={{ width: '42.79%' }}>
                      참여 종료 시간
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {zoomDetailQueryData?.data?.items?.map((item, index) => {
                    if (!item.joinTime || !item.leaveTime) return;
                    return (
                      <tr className='border-bottom'>
                        <td className='py-3 text-center'>{index + 1}</td>

                        <td className='py-3'>{moment(item.joinTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                        <td className='py-3'>{moment(item.leaveTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </>
        }
      </div>
    </>
  );
}
