import { useNavigate } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { zoomStudentState } from 'recoil/zoom/zoomStudentState';

export const ZoomCardTitle = () => {
  const user = useRecoilValue(loginUser);
  const navigate = useNavigate();
  const [zoomState, setZoomState] = useRecoilState(zoomStudentState);
  return (
    <>
      <section className='card p-20 py-4 d-flex justify-content-between align-items-center flex-row'>
        <div className='d-flex fw-bold fz-18'>
          <h3 className='d-flex --Palette-DarkGray-80'>{zoomState?.subjectInformation?.course_name}</h3>
        </div>
        <div className='d-flex fz-14 noto-sans align-items-center'>
          <p className='ms-3 fz-12 d-flex align-items-center p-2 rounded text-blue-primary CHA_LIGHT me-2'>
            <span className=''>교과코드:</span>
            <span className='fw-bold'>{zoomState?.subjectInformation?.course_code}</span>
          </p>
          {user.role !== 'STUDENT' && (
            <button
              onClick={() => navigate('/zoom/setting')}
              className='py-2 px-3 border border-primary bg-none rounded-1 color-primary'
            >
              <i className='bi bi-gear-fill me-1'></i>
              출석설정
            </button>
          )}
        </div>
      </section>
    </>
  );
};
