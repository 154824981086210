import InfoIcon from 'assets/svg/info-circle.svg';
import { useEffect, useState } from 'react';
import CommonToolTip from './CommonToolTip';
function ResponsiveToolTip({ children }) {
  const [show, setShow] = useState(false);

  const handleSwitchShow = () => {
    setShow(!show);
  };
  useEffect(() => {
    return () => {
      setShow(false);
    };
  }, []);
  return (
    <>
      <div className='d-block d-sm-none'>
        <div className='d-flex flex-column align-items-end position-relative'>
          {show && (
            <div className='d-inline triangular_border-top mb-3 position-absolute white-nowrap'>
              <CommonToolTip>{children}</CommonToolTip>
            </div>
          )}
          <button className='border-none bg-none d-inline p-0' onClick={handleSwitchShow}>
            <img className='me-2' src={InfoIcon} alt='느낌표모양 동그라미 아이콘' width={24} height={24} />
          </button>
        </div>
      </div>

      <div className='d-none d-sm-block'>
        <CommonToolTip>{children}</CommonToolTip>
      </div>
    </>
  );
}

export default ResponsiveToolTip;
