import { useEffect, useRef, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { ATTENDANCETABLETH, ATTENDANCETABLETHx2 } from 'assets/constant';
import { SpinnerLoading } from 'components/common';
import EmptyComponent from 'components/common/EmptyComponent';
import { IsZoomSuccess } from 'components/common/IsZoomSuccess';
import { NotFoundText } from 'components/common/NotFoundText';
import { useGetCommonApi } from 'hooks';
import { useGetRequiredApi } from 'hooks/useGetRequiredApi';
import SubjectTitleCard from 'pages/student/subject/SubjectTitleCard';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { useRecoilState, useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { subjectValueState } from 'recoil/subject/subjectValueState';
import { mixVideoArr, videoStateSwitch } from 'util/attendance/attendTeacher';
import { jandiErrorHandle } from 'util/jandi/jandiUtil';

export function AttendanceTeacher() {
  const navigate = useNavigate();
  const user = useRecoilValue(loginUser);
  const [subjectState, setSubjectState] = useRecoilState(subjectValueState);
  const [courseId, setCourseId] = useState(null); // [과목 선택] 드롭다운 선택된 값
  const [optionData, setOptionData] = useState([]);
  const inputRef = useRef(null);
  const [filterDataList, setFilterDataList] = useState([]); // 검색 결과 데이터
  const [errorHandle, setErrorHandle] = useState(false); // 에러 핸들링

  const {
    // 교수자 / 관리자 - [학습현황] 등록되어 있는 게시중인 과목 리스트 정보 API
    // subject 상위 select-box list
    teacherSelectBoxQueryData,
    teacherSelectBoxQueryIsLoading,
    teacherSelectBoxQueryIsError,
  } = useGetRequiredApi();

  const { semesterData, semesterIsLoading, semesterIsError } = useGetCommonApi();

  const handleSelectBox = (e) => {
    const findData = teacherSelectBoxQueryData?.find((el) => el.course_id === e.value);
    setCourseId(e.value);
    setSubjectState({ ...findData, name: findData?.course_name });
  };

  const { data: jandiData, isLoading: jandiIsLoading } = useQuery({
    queryKey: ['attendanceTeacher', courseId, user],
    queryFn: () => getDataQuery(`/teach/courses/${courseId}/attendance`),
    // queryFn: () => getDataQuery(`/teach/home/course/${courseId}/students/jandi-info`),
    enabled: !!courseId,
    select: (data) => data.data,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const jandiList = jandiData?.filter(
      (el) => el?.name?.includes(inputRef.current.value) || el?.userNumber?.includes(inputRef.current.value)
    );
    setFilterDataList(jandiList);
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(
        teacherSelectBoxQueryData?.map((item) => ({
          value: item.course_id,
          label: item.course_name,
        }))
      );
    });
  useEffect(() => {
    const transformOption = teacherSelectBoxQueryData?.map((el) => ({
      value: el.course_id,
      label: el.course_name,
    }));

    setOptionData(transformOption);
  }, [teacherSelectBoxQueryData]);

  useEffect(() => {
    if (!jandiData) return;

    if (jandiErrorHandle(jandiData)) {
      setFilterDataList([]);
      setErrorHandle(true);
      return;
    }
    setFilterDataList(jandiData);
  }, [jandiData]);

  return (
    <>
      <section className=''>
        <div className='row'>
          <div className='col'>
            <div className='position-relative'>
              {/* 위쪽 */}
              <div className='card p-20 px-4'>
                {/* 헤더 */}
                <div className='d-flex justify-content-between align-items-center'>
                  <h3 className='d-flex align-items-center white-nowrap fw-bold'>
                    <i className='bi bi-bank2 me-2 color-primary'></i>
                    {/* 학기 정보 */}
                    {semesterData ? semesterData?.data?.name : '기본학기'}
                  </h3>

                  <div className='d-flex align-items-center w-100 ps-3'>
                    <label htmlFor='subjectSelectTitle' className='white-nowrap me-3 d-none d-sm-inline'>
                      과목
                    </label>

                    <AsyncSelect
                      className='w-100'
                      defaultInputValue='과목 선택'
                      placeholder='과목 선택'
                      isLoading={teacherSelectBoxQueryIsLoading}
                      cacheOptions
                      defaultOptions={optionData}
                      onChange={handleSelectBox}
                      loadOptions={promiseOptions}
                    />
                  </div>
                </div>
              </div>

              {jandiIsLoading && (
                <div className='card p-3 w-100' style={{ height: '100px' }}>
                  <SpinnerLoading />
                </div>
              )}

              {errorHandle ? (
                <div className='card p-3 py-5 not-found h-100'>
                  <NotFoundText firstNumber={''} secondNumber={''}>
                    연동중 입니다.
                  </NotFoundText>
                </div>
              ) : (
                jandiData && (
                  <>
                    <div>
                      <SubjectTitleCard />
                    </div>

                    <div className='card p-4 scrollX position-relative'>
                      <form className='position-sticky top-left-0' onSubmit={handleSubmit}>
                        <input
                          type='text'
                          className='border py-2 px-3 mb-4 w-100 rounded mt-3'
                          ref={inputRef}
                          // onChange={(e) => setInputValue(e.target.value)}
                          // value={inputValue}
                          name='attendanceSearch'
                          id='attendanceSearch'
                          placeholder='이름 / 학번  검색'
                        />
                      </form>

                      {!jandiIsLoading && (
                        <Table>
                          <tr className='border-bottom fw-bold fz-16 white-nowrap'>
                            {/* index */}
                            <th rowSpan={2}>번호</th>
                            {/* 학번 */}
                            <th rowSpan={2} className='text-start'>
                              학번
                            </th>
                            {/* 학생 */}
                            <th rowSpan={2} className='text-start'>
                              이름
                            </th>

                            {/* 집계 부분 */}
                            {/* {ATTENDANCETABLE?.map((el) => (
                              <th key={el}>
                                <div className='w-100 h-100 d-flex justify-content-center'>
                                  <p style={{ width: '70px' }}>{el}</p>
                                </div>
                              </th>
                            ))} */}

                            {/* 주차 부분 */}
                            {ATTENDANCETABLETH.map((el, index) => (
                              <th
                                colSpan={2}
                                className={`${index % 2 !== 0 && 'CHA_LIGHT'} font-center`}
                                key={el}
                                style={{ minWidth: '70px', height: '54px' }}
                              >
                                <div className='w-100 h-100 d-flex justify-content-center'>
                                  <p style={{ minWidth: '88px' }}>{el}</p>
                                </div>
                              </th>
                            ))}
                          </tr>

                          <tr>
                            {/* 집계 부분 */}
                            {/* {ATTENDANCETABLEx2?.map((el, index) => (
                              <td key={el}>
                                <div className='w-100 h-100 d-flex justify-content-center fw-bold'>
                                  <p>{index % 2 === 0 ? 'V' : 'Z'}</p>
                                </div>
                              </td>
                            ))} */}
                            {/* 주차 부분 */}
                            {ATTENDANCETABLETHx2.map((el, index) => (
                              <th key={el}>
                                <p>{index % 2 === 0 ? 'V' : 'Z'}</p>
                              </th>
                            ))}
                          </tr>

                          {filterDataList.length === 0 && (
                            <tr className='border-top border-bottom white-nowrap'>
                              <td colSpan='20' className='text-center py-3'>
                                <div>
                                  <EmptyComponent text='검색 결과가 없습니다.' transform />
                                </div>
                              </td>
                            </tr>
                          )}
                          {filterDataList.map((el, index) => (
                            <tr className='border-top border-bottom white-nowrap' key={el.id}>
                              {/* 번호 */}
                              <td className='px-3 py-3 text-center'>{index + 1}</td>
                              {/* 학번 */}
                              <td className='px-3 py-3'>{el.user_number}</td>
                              {/* 이름 */}
                              <td className='px-3 py-3'>{el.name.split('_')[0]}</td>
                              {/* {el?.jandi?.map((item, index) => {
                                if (index >= 16) return;
                                return (
                                  <td className='text-center px-3 py-3' key={item.position}>
                                    <span
                                      className={`fw-bold noto-sans fz-12 py-2 px-3 rounded-5 text-nowrap lh-22 ${
                                        item.completed ? 'isSuccess-green' : 'isSuccess-red'
                                      }`}
                                    >
                                      {item.completed ? '출석' : '결석'}
                                    </span>
                                  </td>
                                );
                              })} */}

                              {mixVideoArr(el?.panopto_attendances, el?.zoom_attendances)?.map((item) => {
                                return (
                                  <td className='text-center px-3 py-3' key={item.item_number}>
                                    <IsZoomSuccess state={videoStateSwitch(item.attendance_state)}></IsZoomSuccess>
                                  </td>
                                );
                              })}
                              {/* {stretchVideoArr(el?.panopto_attendances)?.map((item, index) => {
                                if (index >= 16) return;
                                return (
                                  <td className='text-center px-3 py-3' key={item.item_number}>
                                    {index + 1}
                                    <IsZoomSuccess state={videoStateSwitch(item.attendance_state)}></IsZoomSuccess>
                                  </td>
                                );
                              })}
                              {stretchVideoArr(el?.zoom_attendances)?.map((item, index) => {
                                if (index >= 16) return;
                                return (
                                  <td className='text-center px-3 py-3' key={item.item_number}>
                                    {index + 1}
                                    <IsZoomSuccess state={videoStateSwitch(item.attendance_state)}></IsZoomSuccess>
                                  </td>
                                );
                              })} */}
                            </tr>
                          ))}
                        </Table>
                      )}
                    </div>
                  </>
                )
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
