import { removeSessionStorage, setSessionStorage } from 'assets/constant';
import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { useUserState } from 'recoil/useUserState';
import { getCookie, removeCookies, setCookie } from 'util/cookie';

export default function Haksa() {
  const [user, setUser] = useRecoilState(useUserState);
  const setLoginUser = useSetRecoilState(loginUser);
  const falseFetching = () => {
    removeSessionStorage('accessToken');
    removeCookies('refreshToken');
    navigate('/login');
    return;
  };
  const navigate = useNavigate();
  const getFn = async () => {
    try {
      const accessCookie = getCookie('YWNjZXNzdG9rZW4v');
      const refreshCookie = getCookie('cmVmcmVzaHRva2Vu');
      const option = {
        maxAge: 60 * 60 * 11, // 2시간 + UTC 시간차이
      };

      removeCookies('YWNjZXNzdG9rZW4v');
      removeCookies('cmVmcmVzaHRva2Vu');

      if (accessCookie && refreshCookie) {
        setCookie('refreshToken', refreshCookie, option);
        setSessionStorage(accessCookie, 'accessToken');

        setUser(jwtDecode(accessCookie));
        setLoginUser(jwtDecode(accessCookie));

        // +*********차의대만 적용************5
        // 나머지는 '/' 기본주소로 이동
        navigate('/notice');
        return;
      }
      falseFetching();
      navigate('/login');
      return;
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getFn();
  }, []);

  return (
    <>
      <div className='loader'>
        <div className='item-1'>
          <span></span>
        </div>
        <div className='item-2'>
          <span></span>
        </div>
        <div className='item-3'>
          <span></span>
        </div>
        <div className='item-4'>
          <span></span>
        </div>
        <div className='item-5'>
          <span></span>
        </div>
        <div className='item-6'>
          <span></span>
        </div>
        <div className='item-7'>
          <span></span>
        </div>
        <div className='item-8'>
          <span></span>
        </div>
      </div>
    </>
  );
}
