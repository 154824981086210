import { pdfStudentArrList, pdfTeacherArrList } from 'assets/constant';
import BreadCrumb from 'components/shared/BreadCrumb';
import { Sidebar } from 'components/shared/sidebar';
import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { handleOpenManual } from 'util/supporting/menual';

export const Manual = () => {
  const [key, setKey] = useState('student');
  const navigate = useNavigate();

  return (
    <>
      <Sidebar />
      <main id='main' className='main'>
        <BreadCrumb home={'/manual'} sublink={'/manual'} title={'Help Center'} subTitle={'지원센터'}>
          &nbsp;/ 매뉴얼
        </BreadCrumb>

        <div className='card py-20 px-1'>
          <Tabs
            activeKey={key}
            onSelect={(k) => setKey(k)}
            defaultActiveKey='home'
            transition={false}
            id='noanim-tab-example'
            className='mb-3'
          >
            <Tab eventKey='student' title='학습자'>
              <div className='pb-3'>
                <ul className='row px-5 gapY-4'>
                  {pdfStudentArrList.map((item) => (
                    <li
                      className='col-4 px-5 d-flex flex-column justify-content-center align-items-center mb-4'
                      key={item}
                    >
                      <div className='border rounded max-w-270'>
                        <div className='d-flex justify-content-center'>
                          <img src='/assets/img/manual/img.jpg' alt='이미지' />
                        </div>
                        <div className='px-3 text-start'>
                          <h3 className='my-2 fz-18 fw-bold'>LMS 학습자 가이드</h3>
                          <p className='my-1'>업데이트: {item.createAt}</p>
                          <div>
                            <p className='py-2 px-0 text_dat text-truncate d-block text_dat text-start'>{item.name}</p>
                          </div>
                          <button
                            onClick={() => handleOpenManual(item.path, 'student')}
                            className='badge link-badge border fz-14 mt-2 mb-4'
                          >
                            매뉴얼 보기
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Tab>
            <Tab eventKey='teacher' title='교수자'>
              <div className='pb-3'>
                <ul className='row px-5 gapY-4'>
                  {pdfTeacherArrList.map((item) => (
                    <li
                      className='col-4 px-5 d-flex flex-column justify-content-center align-items-center mb-4'
                      key={item}
                    >
                      <div className='border rounded max-w-270'>
                        <div className='d-flex justify-content-center'>
                          <img src='/assets/img/manual/img.jpg' alt='이미지' />
                        </div>
                        <div className='px-3 text-start'>
                          <h3 className='my-2 fz-18 fw-bold'>LMS 교수자 가이드</h3>
                          <p className='my-1'>업데이트: {item.createAt}</p>
                          <div>
                            <p className='py-2 px-0 text_dat text-truncate d-block text_dat text-start'>{item.name}</p>
                          </div>
                          <button
                            onClick={() => handleOpenManual(item.path, 'teacher')}
                            className='badge link-badge border fz-14 mt-2 mb-4'
                          >
                            매뉴얼 보기
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Tab>
            <Tab eventKey='admin' title='관리자' disabled>
              <div className='pb-3'>
                <ul className='d-flex justify-content-evenly'>
                  <li>
                    <div>
                      {/* imgbox */}
                      <img src='/assets/img/manual/img.jpg' alt='이미지' />
                    </div>
                    <div>
                      {/* textbox */}
                      <h3 className='my-2'>LMS 교수자 가이드</h3>
                      <p className='my-1'>업데이트: 2020.02.02</p>
                      <div>
                        <button className='btn btn-style-none px-0'>{`[과제 제출하기]`}</button>
                      </div>
                      <button className='badge link-badge border fz-14'>매뉴얼 보기</button>
                    </div>
                  </li>
                  <li>
                    <div>
                      {/* imgbox */}
                      <img src='/assets/img/manual/img.jpg' alt='이미지' />
                    </div>
                    <div>
                      {/* textbox */}
                      <h3 className='my-2'>LMS 교수자 가이드</h3>
                      <p className='my-1'>업데이트: 2020.02.02</p>
                      <div>
                        <button className='btn btn-style-none px-0'>{`[과제 제출하기]`}</button>
                      </div>
                      <button className='badge link-badge border fz-14'>매뉴얼 보기</button>
                    </div>
                  </li>
                  <li>
                    <div>
                      {/* imgbox */}
                      <img src='/assets/img/manual/img.jpg' alt='이미지' />
                    </div>
                    <div>
                      {/* textbox */}
                      <h3 className='my-2'>LMS 교수자 가이드</h3>
                      <p className='my-1'>업데이트: 2020.02.02</p>
                      <div>
                        <button className='btn btn-style-none px-0'>{`[과제 제출하기]`}</button>
                      </div>
                      <button className='badge link-badge border fz-14'>매뉴얼 보기</button>
                    </div>
                  </li>
                </ul>
              </div>
            </Tab>
          </Tabs>
        </div>
      </main>
    </>
  );
};
