import { zoomSettingArr } from 'assets/constant';
import BreadCrumb from 'components/shared/BreadCrumb';
import { useGetCommonApi } from 'hooks';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router';

export function ZoomSetting() {
  const [attendType, setAttendType] = useState('entrance');
  const navigate = useNavigate();
  const { semesterData, semesterIsLoading, semesterIsError } = useGetCommonApi();
  const handleGoingZoomList = () => {
    navigate('/zoom');
  };

  const handleAttendSeletType = (e) => {
    setAttendType(e.target.value);
  };

  return (
    <>
      <BreadCrumb
        home={'/subject'}
        sublink={'/subject'}
        title={'ZOOM Attendance'}
        subTitle={'Zoom 출석현황 '}
      ></BreadCrumb>
      <section>
        <div className='row'>
          <div className='col'>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              className=''
            >
              {/* 위쪽 */}
              <div className='card p-20 px-4'>
                {/* 헤더 */}
                <div className='d-flex justify-content-between align-items-center'>
                  {/* <CardHeader title='과목별 성적' margin={false}>
                      {semesterIsLoading ? <div>로딩중</div> : semesterData?.name}
                    </CardHeader> */}

                  <h3 className='d-flex align-items-center white-nowrap fw-bold'>
                    <i className='bi bi-bank2 me-2 color-primary'></i>
                    {/* 학기 정보 */}
                    ZOOM 출석 설정
                  </h3>

                  <div className='d-flex align-items-center'>
                    <button
                      onClick={handleGoingZoomList}
                      className='px-3 py-2 Blue_primary text-white rounded border-blue-primary border-1'
                    >
                      목록
                    </button>
                  </div>
                </div>
              </div>
              <div className='card p-20'>
                <div className='mb-4'>
                  <label className='mb-2 fw-500' htmlFor='attendSeletType'>
                    Zoom 출석 인정 기준
                  </label>
                  {/* <Form.Select onChange={handleAttendSeletType} value={attendType} id='attendSeletType'> */}
                  <Form.Select onChange={handleAttendSeletType} value={'Attendance'} disabled id='attendSeletType'>
                    <option value='entrance'>ZOOM 입장</option>
                    <option value='Attendance'>출석 인정 참여시간 이상</option>
                  </Form.Select>
                </div>
                <div>
                  <label className='mb-2 fw-500' htmlFor='AttendanceCheck'>
                    출석 인정 참여시간 (ZOOM 총 참여시간 기준)
                  </label>
                  <Form.Select disabled id='AttendanceCheck' value='95'>
                    <option value=''>선택</option>
                    {zoomSettingArr.map((item) => (
                      <option key={item} value={item} className=''>
                        {item}&#37;
                      </option>
                    ))}
                  </Form.Select>
                </div>

                <div className='d-flex justify-content-start align-items-center mt-3'>
                  <button className='px-3 border-blue-primary border-1 py-2 Blue_primary text-white rounded'>
                    저장
                  </button>
                  <button className='btn btn-outline-secondary rounded px-3 py-1 ms-3' onClick={handleGoingZoomList}>
                    취소
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
