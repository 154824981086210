import { useQuery } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { getSessionStorage, setSessionStorage } from 'assets/constant';
import EmptyComponent from 'components/common/EmptyComponent';
import { PrimaryButton } from 'components/common/button/PrimaryButton';
import { ZoomIsSuccess } from 'components/common/zoom/ZoomIsSuccess';
import BreadCrumb from 'components/shared/BreadCrumb';
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { zoomTypeSwitch } from 'util/zoom/zoomUtil';

export function ZoomDetail() {
  const navigate = useNavigate();
  const user = useRecoilValue(loginUser);
  const getDetailData = JSON.parse(getSessionStorage('zoomDetail'));
  const [search, setSearch] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);
  const [filterIndex, setFilterIndex] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const [groupbyState, setGroupbyState] = useState([]);

  const { data: zoomDetailData } = useQuery({
    queryKey: ['zoomDetail', user, getDetailData],
    queryFn: async () =>
      getDataQuery(`/zoom/attendance?courseId=${getDetailData?.courseId}&zoomTopic=${getDetailData.zoomTopic}`),
    enabled: !!getDetailData.courseId && !!getDetailData.email && !!getDetailData.zoomTopic,
  });

  const handleBtnClick = (index, type) => {
    setFilterIndex(index);

    if (type === 'all') {
      setActiveIndex(0);
      setFilterData(zoomDetailData?.data?.attendanceItems);
    }
    if (type === 'completed') {
      setActiveIndex(1);
      setFilterData(groupbyState?.Y);
    }
    if (type === 'notRecognized') {
      setActiveIndex(2);
      setFilterData(groupbyState?.N);
    }
    if (type === 'absent') {
      setActiveIndex(3);
      setFilterData(groupbyState?.U);
    }
    if (type === 'Interworking') {
      setActiveIndex(4);
      setFilterData(groupbyState?.null);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const searchFilter = zoomDetailData?.data?.attendanceItems?.filter((el) => el.userName.includes(e.target.value));

    setFilterData(searchFilter);
  };

  useEffect(() => {
    if (!zoomDetailData?.data.meetingId || !zoomDetailData?.data?.attendanceItems?.length === 0) {
      return setFilterData([]);
    }
    setFilterData(zoomDetailData?.data?.attendanceItems);
    if (zoomDetailData) {
      const groupbyState = Object.groupBy(zoomDetailData?.data?.attendanceItems, ({ isAttended }) => isAttended);
      setGroupbyState(groupbyState);
    }
  }, [zoomDetailData]);

  return (
    <>
      <BreadCrumb home={'/zoom'} sublink={'/zoom'} title={'ZOOM Attendance'} subTitle={'Zoom 출석현황'}>
        <Link className='ms-1 --Palette-DarkGray-60 '>
          {/* &nbsp;/<span> {getDetailData?.subjectTitle}</span>&nbsp;/<span> {zoomDetailData?.data?.topic}</span> */}
        </Link>
      </BreadCrumb>
      <section>
        <div className='row'>
          <div className='col'>
            <div className=''>
              {/* 위쪽 */}
              <div className='card p-20 px-4'>
                {/* 헤더 */}
                <div className='d-flex justify-content-between align-items-center'>
                  <h3 className='d-flex align-items-center white-nowrap fw-bold fz-24'>
                    {zoomDetailData?.data?.topic}
                  </h3>

                  <PrimaryButton onClick={() => navigate('/zoom', { replace: true })}>목록</PrimaryButton>
                </div>
              </div>

              {
                // subjectMainData
                <>
                  {/* 셀렉트 information */}

                  {/* 아래 카드 */}
                  <div className='card p-20'>
                    <div className='mb-20 d-flex justify-content-between align-items-center'>
                      {/* //TODO 코드 리펙토링해야함 */}
                      <ul className='d-flex '>
                        <li className='me-3 '>
                          <button
                            onClick={() => {
                              handleBtnClick(0, 'all');
                            }}
                            className={`btn btn-style-none btn-filter-btn ${activeIndex === 0 && 'btn-filter-active'}`}
                          >
                            전체 &#40;{zoomDetailData?.data?.attendanceItems?.length}&#41;
                          </button>
                        </li>
                        <li className='me-3'>
                          <button
                            onClick={() => {
                              handleBtnClick(1, 'completed');
                            }}
                            className={`btn btn-style-none btn-filter-btn ${activeIndex === 1 && 'btn-filter-active'}`}
                          >
                            출석 &#40;{groupbyState?.Y?.length ? groupbyState?.Y?.length : 0}&#41;
                            {/* {subjectUserList?.data.filter((el) => el.assignmentState !== 'UNCOMPLETE').length}&#41; */}
                          </button>
                        </li>
                        <li className='me-3 '>
                          <button
                            onClick={() => {
                              handleBtnClick(2, 'notRecognized');
                            }}
                            className={`btn btn-style-none btn-filter-btn ${activeIndex === 2 && 'btn-filter-active'}`}
                          >
                            미인정 &#40;{groupbyState?.N?.length ? groupbyState?.N?.length : 0}&#41;
                          </button>
                        </li>
                        <li className='me-3 '>
                          <button
                            onClick={() => {
                              handleBtnClick(3, 'absent');
                            }}
                            className={`btn btn-style-none btn-filter-btn ${activeIndex === 3 && 'btn-filter-active'}`}
                          >
                            결석 &#40;{groupbyState?.U?.length ? groupbyState?.U?.length : 0}&#41;
                          </button>
                        </li>
                        <li className='me-3 '>
                          <button
                            onClick={() => {
                              handleBtnClick(4, 'Interworking');
                            }}
                            className={`btn btn-style-none btn-filter-btn ${activeIndex === 4 && 'btn-filter-active'}`}
                          >
                            연동중 &#40;{groupbyState?.null?.length ? groupbyState?.null?.length : 0}&#41;
                          </button>
                        </li>
                      </ul>
                      <input
                        type='text'
                        onChange={handleSearch}
                        value={search}
                        className='border rounded py-2 px-3 w-50'
                        name=''
                        id=''
                        placeholder='이름 검색'
                      />
                    </div>

                    {zoomDetailData?.data.meetingId ? (
                      <>
                        <Table>
                          <thead className='fw-bold border-bottom'>
                            <tr>
                              <th className='py-3'>번호</th>
                              <th className='py-3 text-start'>학번</th>
                              <th className='py-3 text-start' style={{ width: '30%' }}>
                                학생
                              </th>
                              <th className='py-3'>상태</th>
                              <th className='py-3 text-start'>참여시간</th>
                              <th className='py-3 text-start'>참여율</th>
                              <th className='py-3'>참여현황</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!filterData && (
                              <tr>
                                <td colSpan={10}>
                                  <EmptyComponent transform={true} text='유저가 없습니다.' />
                                </td>
                              </tr>
                            )}
                            {filterData?.map((item, index) => {
                              const userName = item.userName?.split('_')[0];
                              const userNumber = item.userName?.split('_')[1];
                              const disabledBtn = item.isAttended !== 'Y' || item.isAttended === 'N';

                              return (
                                <tr className='border-bottom'>
                                  <td className='py-3 text-center'>{index + 1}</td>
                                  <td className='py-3 text-start'>{userNumber}</td>
                                  <td className='py-3 text-start'>{userName}</td>
                                  <td className='py-3'>
                                    <div className='w-100 h-100 d-flex justify-content-center'>
                                      <ZoomIsSuccess type={zoomTypeSwitch('_', item.isAttended)} />
                                    </div>
                                  </td>
                                  <td className='py-3 text-start'>{item.totalTime}</td>
                                  <td className='py-3 text-start'>{item.percentage ? `${item.percentage}%` : '-'}</td>
                                  <td className='py-3'>
                                    <div className='w-100 h-100 d-flex justify-content-center'>
                                      <button
                                        onClick={() => {
                                          setSessionStorage(item.userName, 'zoomDetailStudent');
                                          navigate(`/zoom/detail/student/${zoomDetailData?.data?.meetingId}`);
                                        }}
                                        disabled={disabledBtn}
                                        className={`btn text-white ${disabledBtn ? 'bg-gray' : 'bg-second'}`}
                                      >
                                        자세히 보기
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </>
                    ) : (
                      <div className='text-center py-3 fw-bold fz-24'>연동중입니다</div>
                    )}
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
