import axios from 'axios';

const postLogin = async (login) => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_SERVER}/signin`, login);
    return data;
  } catch (error) {
    console.error('로그인 에러', error);
  }
};

export { postLogin };
