/**
 * Zoom 유틸리티 함수로, Zoom 출석 상태를 확인하여 성공 여부를 반환합니다.
 * @param {boolean} type - Zoom 연동 여부를 나타내는 값 (기본값: false)
 * @param {string} attendance - Zoom 출석 상태를 나타내는 값 ('Y': 출석, 'N': 미인정, 'U': 결석)
 * @returns {boolean|string} - Zoom 출석 상태가 '연동중'인 경우 true를 반환하고, 그 외의 경우 출석 상태 문자열을 반환합니다.
 */
export const zoomTypeSwitch = (type = false, attendance = '') => {
  let state = '';

  if (!type) {
    state = 'gray';
    return state;
  }

  switch (attendance) {
    case 'Y':
      state = 'green';
      break;
    case 'N':
      state = 'orange';
      break;
    case 'U':
      state = 'red';
      break;
    case 'found':
      state = 'gray';
      break;

    default:
      state = '';
      break;
  }

  return state;
};
