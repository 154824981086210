import { useQuery } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { setSessionStorage } from 'assets/constant';
import { SpinnerLoading } from 'components/common';
import EmptyComponent from 'components/common/EmptyComponent';
import { SubjectTeacherPopup } from 'components/popup';
import BreadCrumb from 'components/shared/BreadCrumb';
import { useStudentZoomQuery } from 'hooks/student/useStudentZoomQuery';
import { useGetRequiredApi } from 'hooks/useGetRequiredApi';
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { zoomStudentState } from 'recoil/zoom/zoomStudentState';
import { zoomTypeSwitch } from 'util/zoom/zoomUtil';
import { ZoomCardTitle } from './ZoomCardTitle';
import { ZoomHeader } from './ZoomHeader';

export function ZoomTeacher() {
  const user = useRecoilValue(loginUser);
  const navigate = useNavigate();

  const [zoomState, setZoomState] = useRecoilState(zoomStudentState);
  const [subjectId, setSubjectId] = useState(null);
  const [optionData, setOptionData] = useState([]);
  const [visiblePopup, setVisiblePopup] = useState(false);
  const [filterData, setFilterData] = useState([]);

  const {
    // 학생 - [zoom 출석현황] 과목 드롭다운 API
    studentZoomSelectListData,
    studentZoomSelectListIsLoading,
    studentZoomSelectListIsError,
  } = useStudentZoomQuery();

  const {
    // 교수자 / 관리자 - [학습현황] 등록되어 있는 게시중인 과목 리스트 정보 API
    // subject 상위 select-box list
    teacherSelectBoxQueryData,
    teacherSelectBoxQueryIsLoading,
    teacherSelectBoxQueryIsError,
  } = useGetRequiredApi();

  const {
    data: zoomWeekData,
    isLoading: zoomWeekDataIsLoading,
    isError: zoomWeekDataIsError,
  } = useQuery({
    queryKey: ['zoomWeekData', zoomState.subjectId, user],
    queryFn: () => getDataQuery(`/zoom/attendance/enrollment?courseId=${zoomState.subjectId}`),
    enabled: !!zoomState.subjectId,
  });

  const handleGoingSetting = () => {
    navigate('/zoom/setting');
  };

  const handleGoingDetail = (title) => {
    const zoomDetailData = JSON.stringify({
      courseId: zoomState.subjectId,
      zoomTopic: title,
      email: user.sub,
      subjectTitle: zoomState.subjectInformation.courseName,
    });
    setSessionStorage(zoomDetailData, 'zoomDetail');
    navigate(`/zoom/detail`);
  };
  useEffect(() => {
    setFilterData(zoomWeekData?.data);
  }, [zoomWeekData]);

  return (
    <>
      <BreadCrumb home={'/zoom'} sublink={'/zoom'} title={'ZOOM Attendance'} subTitle={'Zoom 학습현황'} />

      <section>
        <div className='row'>
          <div className='col'>
            <div className=''>
              {/* 위쪽 */}
              <ZoomHeader data={teacherSelectBoxQueryData} isLoading={teacherSelectBoxQueryIsLoading} />
              {zoomWeekDataIsLoading && <SpinnerLoading />}
              {studentZoomSelectListData && (
                <>
                  {/* 셀렉트 information */}
                  <div className=''>
                    {/* 아래 카드 */}
                    <div>
                      {/* 주차 아이템 */}

                      {zoomWeekData && (
                        <>
                          {/* 셀렉트 information */}
                          <div className=''>
                            <ZoomCardTitle />

                            {/* 아래 카드 */}
                            <div>
                              {/* 주차 아이템 */}

                              {filterData?.map((item, index) => (
                                <>
                                  <div key={item.moduleId} className='rounded-2 overflow-hidden bg-white mb-5'>
                                    <div className='p-20 Blue_primary fw-bold text-white'>
                                      <span className='me-1'>{index + 1}</span>주차
                                    </div>
                                    <div className=''>
                                      {item.zoomAttendanceTeacherItems?.length === 0 ? (
                                        <div className='p-30 pb-2 fz-14'>
                                          <EmptyComponent transform={true} text='등록된 강의가 없습니다.' />
                                        </div>
                                      ) : (
                                        <div className='p-30 pb-2 fz-14'>
                                          <Table className=''>
                                            <thead className='fw-bold --Palette-DarkGray-80'>
                                              <tr className='border-bottom'>
                                                <th
                                                  colSpan={2}
                                                  className='text-start p-1 py-3'
                                                  style={{ width: '39.29%' }}
                                                >
                                                  제목
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody className='--Palette-DarkGray-80'>
                                              {item.zoomAttendanceTeacherItems?.map((el) => {
                                                const zoomType = zoomTypeSwitch(el.found, el.isAttendance);
                                                return (
                                                  <tr key={el.meetingId} className='border-bottom'>
                                                    <td className='text-start p-1 py-3'>{el.title}</td>

                                                    <td className='text-start p-1 py-3'>
                                                      <div className='d-flex justify-content-end align-items-center'>
                                                        <button
                                                          onClick={() => handleGoingDetail(el.title)}
                                                          // to={`/zoom/detail/${el.meetingId}`}
                                                          className={`border-none py-2 px-3 rounded-2 text-white bg-primary`}
                                                        >
                                                          자세히보기
                                                        </button>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                            </tbody>
                                          </Table>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      {visiblePopup && <SubjectTeacherPopup setVisiblePopup={setVisiblePopup} />}
    </>
  );
}
