import { useEffect } from 'react';
import { Outlet } from 'react-router';

import { useRecoilValue, useResetRecoilState } from 'recoil';
import { zoomStudentState } from 'recoil/zoom/zoomStudentState';

export function ZoomTeacherLayout() {
  const zoomState = useRecoilValue(zoomStudentState);
  const resetZoomState = useResetRecoilState(zoomStudentState);

  useEffect(() => {
    return () => {
      resetZoomState();
    };
  }, []);

  return (
    <>
      <main id='main' className={`main ${!zoomState.subjectId && 'subject-icon-backdrop'}`}>
        <Outlet />
      </main>
    </>
  );
}
